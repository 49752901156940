<template>
    <div>
        <ContaUsuario></ContaUsuario>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import ContaUsuario from '@/components/Configuracoes/contaUsuario.vue';

export default {
  components: {
    BaseView,
    ContaUsuario
  }
}
</script>